import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { spaceChildren } from '../../styles/mixins';
import { theme } from '../../styles/theme';
import AutoLink from './AutoLink';

const Button = (props: ButtonHTMLAttributes<HTMLButtonElement> & {
  primary?: boolean;
  secondary?: boolean;
  color?: string;
  newTab?: boolean;
  href?: string;
  rel?: string;
}) => {
  const {
    type = 'button',
    primary = false,
    secondary = false,
    color = theme.colors.primary,
    href,
    rel,
    newTab,
    ...rest
  } = props;

  let Component = SecondaryStyle;
  if (primary) {
    Component = PrimaryStyle;
  } else if (secondary) {
    Component = SecondaryStyle;
  }

  const button = <Component type={type} color={color} {...rest} />;

  if (href) {
    return <StyledLink to={href} rel={rel} newTab={newTab}>{button}</StyledLink>;
  }

  return button;
};

export default Button;

export const IconButton = styled(Button)`
  ${spaceChildren('4px')};
`;

const StyledLink = styled(AutoLink)`
  text-decoration: none;
`;
const CommonStyles = styled.button`
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 2px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: ${theme.fonts.sansSerif};
  transition: 0.3s;
  border: 1px solid transparent;
  text-decoration: none;
  
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;
const PrimaryStyle = styled(CommonStyles)<{ color: string }>`
  border-color: white;
  background-color: ${p => p.color};
  color: white;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    border-color: ${p => p.color};
    color: ${p => p.color};
  }
`;
const SecondaryStyle = styled(CommonStyles)<{ color: string }>`
  border-color: ${p => p.color};
  background-color: white;
  color: ${p => p.color};

  &:hover,
  &:focus,
  &:active {
    background-color: ${p => p.color};
    border-color: ${p => p.color};
    color: #fff;
  }
`;