import * as React from 'react';
import { DateTime } from 'luxon';
import { FaCalendar } from 'react-icons/all';
import styled from 'styled-components';
import { spaceChildren } from '../../styles/mixins';

type PropsType = {
  date: string;
}

export default function PostDate({ date }: PropsType) {
  const dateTime = DateTime.fromISO(date);
  const format = dateTime.toLocaleString(DateTime.DATE_FULL);

  return (
    <Flex>
      <FaCalendar />
      <span>{format}</span>
    </Flex>
  );
}

const Flex = styled.div`
  display: flex;
  ${spaceChildren('8px')};
  color: #939393;
`;

