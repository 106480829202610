import * as React from 'react';
import { graphql } from 'gatsby';
import { FaGlobe } from 'react-icons/all';
import styled from 'styled-components';
import { IconButton } from '../components/common/Button';
import PostDate from '../components/common/PostDate';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function Template({
  data,
}: {
  data: GatsbyTypes.PostDetailsQuery;
}) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { title, date, passthroughUrl } = frontmatter;

  return (
    <Layout>
      <SEO title={title} />
      <div className="blog-post">
        <h1>{title}</h1>
        <PostDate date={date} />
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {!!passthroughUrl && (
          <IconButtonStyled href={passthroughUrl} newTab>
            <span>View linked content</span>
            <FaGlobe />
          </IconButtonStyled>
        )}
      </div>
    </Layout>
  );
}

type PostNode = {
  html: string;
  frontmatter: {
    title: string;
    date: string;
    slug: string;
  };
};

const IconButtonStyled = styled(IconButton)`
  margin-top: 12px;
`;

export const postQuery = graphql`
  query PostDetails ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
        passthroughUrl
      }
    }
  }
`;
